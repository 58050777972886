import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const ArticleContainer = styled(Link)`
  width: 100%;
  display: ${props => (props.moreArticlesActive ? "grid" : "none")};
  grid-template-columns: 400px 1fr;
  grid-gap: 0 50px;
  border-bottom: 1px solid #c4c4c4;
  margin: 30px 0 0 0;
  text-decoration: none;

  @media (max-width: 1000px) {
    grid-template-columns: 100%;
  }

  @media (max-width: 700px) {
    margin: 20px 0 0 0;
    grid-gap: 0;
  }
`

const ArticleTextWrapper = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  margin: 0 0 30px 0;

  @media (max-width: 700px) {
    width: 100%;
    margin: 0 0 20px 0;
  }
`

const ArticleTitle = styled.h5`
  font-size: 20px;
  color: black;
  font-weight: 400;
  font-family: "MinionPro";
  margin: 0 0 2px 0;
`

const ArticleCredit = styled.p`
  font-size: 14px;
  color: #294973;
  font-family: "franklinGothic";
  font-weight: 400;
  margin: 0 0 15px 0;
`

const ArticleExcerpt = styled.div`
  font-size: 16px;
  color: black;
  font-family: "franklinGothic";
  font-weight: 400;
  margin: 0 0 10px 0;
`

const ArticleDate = styled.p`
  font-size: 14px;
  color: #294973;
  font-family: "franklinGothic";
  font-weight: 400;
  margin: 0;
`

const ArticleImg = styled.img`
  width: 300px;
  background-color: #c4c4c4;
  margin: 0 0 30px 0;

  @media (max-width: 1150px) {
    width: calc(100% - 30px);
  }

  @media (max-width: 1000px) {
    width: 70%;
  }

  @media (max-width: 800px) {
    width: 50%;
  }

  @media (max-width: 700px) {
    width: 100%;
    margin: 0 0 20px 0;
  }
`

const Article = ({
  title,
  date,
  author,
  featured_image,
  excerpt,
  slug,
  moreArticlesActive,
}) => {
  const [articleDate, setArticleDate] = useState("")
  useEffect(() => {
    const editedExcerpt = excerpt.substring(0, excerpt.indexOf(" <a"))
    document.getElementById(`excerpt-${slug}`).innerHTML = editedExcerpt.concat(
      "..."
    )

    document.getElementById(`title-${slug}`).innerHTML = title

    let dateString = date
    let arr_date = dateString.slice(0, 10).split("-"),
      months = [
        "",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ]
    setArticleDate(
      months[parseInt(arr_date[1])] + " " + arr_date[2] + ", " + arr_date[0]
    )
  })
  return (
    <ArticleContainer
      moreArticlesActive={moreArticlesActive}
      to={`/articles/${slug}`}
    >
      <ArticleTextWrapper>
        <ArticleTitle id={`title-${slug}`}></ArticleTitle>
        <ArticleCredit>By {author}</ArticleCredit>
        <ArticleExcerpt id={`excerpt-${slug}`}></ArticleExcerpt>
        <ArticleDate>{articleDate}</ArticleDate>
      </ArticleTextWrapper>
      {featured_image ? (
        <ArticleImg src={featured_image.source_url}></ArticleImg>
      ) : (
        <></>
      )}
    </ArticleContainer>
  )
}

export default Article
