import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import HTMLHeader from "../components/htmlHeader"

import Article from "../components/article"
import { convertPostToOldFormat } from "../lib/wpGraphQLQueryAdapter"

const Content = styled.div`
  width: calc(100% - 230px);
  display: flex;
  flex-direction: column;
  margin: 60px 0 0 0;
  border-right: 1px solid #c4c4c4;

  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (max-width: 700px) {
    margin: 30px 0 0 0;
    border-right: none;
  }
`

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #c4c4c4;
`

const Title = styled.h1`
  font-size: 48px;
  color: black;
  font-family: "MinionPro";
  font-weight: 400;
  margin: 0 0 20px 0;

  @media (max-width: 700px) {
    font-size: 36px;
  }
`

const NothingFound = styled.h5`
  font-size: 24px;
  color: black;
  font-family: "MinionPro";
  font-weight: 400;
  margin: 30px 0 20px 0;

  @media (max-width: 700px) {
    font-size: 18px;
  }
`

const OtherResults = styled.p`
  font-size: 18px;
  color: black;
  font-family: "MinionPro";
  font-weight: 400;
  margin: 30px 0 0 0;
  padding: 0 0 20px 0;
  border-bottom: 1px solid #c4c4c4;
`

const Search = ({ location, data }) => {
  const [matchingArticles, setMatchingArticles] = useState([])

  const posts = data.allWpPost.edges.map(convertPostToOldFormat);

  useEffect(() => {
    let matches = []
    setMatchingArticles(matchingArticles => [])


    if (location && location.state) {
      matches = posts.filter(post => {
        if (post.node.title) {
          return post.node.title
            .toLowerCase()
            .includes(location.state.entry.toLowerCase())
        }
      })
    }

    if (matches.length !== 0) {
      setMatchingArticles(matchingArticles => [...matches])
    }
  }, [location])

  const searchArticles = matchingArticles.map((article, i) => {
    const post = article.node

    return (
      <Article
        title={post.title}
        slug={post.slug}
        author={post.author.name}
        date={post.date}
        featured_image={post.featured_media}
        excerpt={post.excerpt}
        moreArticlesActive={true}
        key={`key-${i}-${post.slug}`}
      />
    )
  })

  const recentArticles = posts
    .filter(post => {
      if (post.node.categories[0].slug !== "gallery") {
        return post
      }
    })
    .slice(0, 3)
    .map((node, i) => {
      const post = node.node
      return (
        <Article
          title={post.title}
          slug={post.slug}
          author={post.author.name}
          date={post.date}
          featured_image={post.featured_media}
          excerpt={post.excerpt}
          key={`key-${i}-${post.slug}`}
          moreArticlesActive={true}
        />
      )
    })

  return (
    <>
      <HTMLHeader />
      <Content>
        <TitleContainer>
          {location && location.state ? (
            <Title>Search results for {location.state.entry}</Title>
          ) : (
            <Title>No search was done</Title>
          )}
        </TitleContainer>
        {matchingArticles.length > 0 ? (
          <>{searchArticles}</>
        ) : (
          <>
            <NothingFound>No search results found.</NothingFound>
            <OtherResults>View our latest articles instead</OtherResults>
            {recentArticles}
          </>
        )}
      </Content>
    </>
  )
}

export default Search

export const searchQuery = graphql`
  query {
    allWpPost(sort: {fields: date, order: DESC}) {
      edges {
        node {
          title
          slug
          excerpt
          featuredImage {
            node {
              title
              sourceUrl
            }
          }
          author {
            node {
              name
            }
          }
          date
          categories {
            nodes {
              slug
            }
          }
        }
      }
    }
  }
`
